
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getImageUrl } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "prescriber-project-component",
  methods: { getImageUrl },
  components: { CardTitleJaya },
  setup: function () {
    const store = useStore();
    let projects = computed(() => store.getters.getUserProjects);
    const deleteProject = (project) => {
      Swal.fire({
        title: `Ne plus participer au projet ${project.name}`,
        icon: "warning",
        html: `En cliquant sur 'Confirmer', vous ne participerez plus au projet ${project.name}. Vous pourrez vous
        réinscrire en suivant de nouveau le lien d'inscription`,
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.DELETE_USER_PROJECT, project.linkId);
        }
      });
    };
    onMounted(() => {
      setCurrentPageTitle("Projets");
      store.dispatch(Actions.FETCH_USER_PROJECTS);
    });
    return { deleteProject, projects };
  },
});
